// @flow
import React, { useState, useEffect } from 'react';
import cx from 'classnames';

import errorMap from '../../constants/error-map';

import styles from './TransactionsPage.module.scss';

const LINE_HEIGHT = 19;

const RejectionReason = ({
  reasonCode,
  className,
}: {
  reasonCode: string,
  className?: string,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [showToggle, setShowToggle] = useState(false);

  const onToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const p = React.useRef(null);

  useEffect(() => {
    if (p && p.current && p.current.clientHeight > LINE_HEIGHT) {
      setShowToggle(true);
    }
  }, []);

  return (
    <div
      className={cx(
        styles['rejection-reason'],
        isCollapsed ? '' : styles.expand,
        className
      )}
    >
      <p ref={p}>{errorMap[reasonCode] || reasonCode}</p>
      {/* eslint-disable-next-line */}
      {showToggle && <i className={styles['btn-toggle']} onClick={onToggle} />}
    </div>
  );
};

RejectionReason.defaultProps = {
  className: '',
};

export default RejectionReason;
