import axios from 'axios';
// import axiosRetry from 'axios-retry';

import { API_HOST } from './constants';

const instance = axios.create({
  baseURL: API_HOST,
  'Content-Type': 'application/json',
});

instance.defaults.headers.common['Paidy-Version'] = '2019-07-11';

// axiosRetry(instance, { retries: 3 });

export default instance;
