// @flow
import React from 'react';

import styles from './Table.module.scss';

const Table = (props: any) => {
  return (
    <table id={props.id} className={styles.table}>
      {props.children}
    </table>
  );
};

export default Table;
