// @flow
import React from 'react';

import styles from './NoResult.module.scss';

const NoResult = () => (
  <article className={styles['no-result']}>
    <h4>No result found</h4>
    <p>
      Please try searching again using different search terms and/or time range.
    </p>
  </article>
);

export default NoResult;
