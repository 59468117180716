import { useSelector, useDispatch } from 'react-redux';

import instance from '../axios';

import { deleteSession } from '../redux/auth';

const useAxios = () => {
  const userToken = useSelector(state => state.auth.userToken);
  const dispatch = useDispatch();

  if (userToken) {
    instance.defaults.headers.common.Authorization = `Bearer ${userToken}`;
  } else {
    delete instance.defaults.headers.common.Authorization;
  }

  const checkTokenStillValid = error => {
    if (userToken && error.response.status === 401) {
      dispatch(deleteSession());
    }

    return Promise.reject(error);
  };

  return {
    get(...args) {
      return instance.get(...args).catch(checkTokenStillValid);
    },
    post(...args) {
      return instance.post(...args).catch(checkTokenStillValid);
    },
    patch(...args) {
      return instance.patch(...args).catch(checkTokenStillValid);
    },
    delete(...args) {
      return instance.delete(...args).catch(checkTokenStillValid);
    },
  };
};

export default useAxios;
