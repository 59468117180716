// @flow
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import { Link } from '@reach/router';
import {
  useQueryParams,
  NumberParam,
  StringParam,
  encodeQueryParams,
  stringify,
} from 'use-query-params';

import RejectionReason from './RejectionReason';
import Header from '../Main/Header';
import Main from '../Main/Main';
import Table from '../Table/Table';
import SearchForm from '../SearchForm/SearchForm';
import Pagination from '../Pagination/Pagination';
import NoResult from '../NoResult/NoResult';
import ErrorResult from '../NoResult/ErrorResult';
import Loading from '../Loading/Loading';

import { updateUIPairs } from '../../redux/ui';

import useAxios from '../../hooks/useAxios';

import {
  divisionMap,
  divisions,
  txTypes,
  paymentStatus,
  PER_PAGE,
  BUCKET_SIZE,
  DEFAULT_RANGE_TYPE,
} from '../../constants';

import {
  now,
  rangeStart,
  rangeEnd,
  dateString,
  pagize,
  currencyFormater,
} from '../../utils';

import styles from './TransactionsPage.module.scss';

const TransactionsPage = ({ scrollMain }: { scrollMain: Function }) => {
  const dispatch = useDispatch();
  const axios = useAxios();

  const [transactions, setTransactions] = useState();
  const [error, setError] = useState(null);

  const qsDef = {
    page: NumberParam,
    queryType: StringParam,
    query: StringParam,
    rangeType: StringParam,
    from: NumberParam,
    to: NumberParam,
  };

  const [qs, setQS] = useQueryParams(qsDef);

  const [page, setPage] = useState(qs.page || 0);

  // query states
  const [queryType, setQueryType] = useState(qs.queryType || '');
  const [query, setQuery] = useState(qs.query || '');

  const [rangeType, setRangeType] = useState(
    qs.rangeType || DEFAULT_RANGE_TYPE
  );
  const [range, setRange] = useState([
    rangeStart({ rangeType, from: qs.from }),
    rangeEnd({ rangeType, to: qs.to }),
  ]);

  // form states
  const [searchQueryType, setSearchQueryType] = useState(qs.queryType || '');
  const [searchQuery, setSearchQuery] = useState(qs.query || '');
  const [searchRangeType, setSearchRangeType] = useState(
    qs.rangeType || DEFAULT_RANGE_TYPE
  );
  const [from, setFrom] = useState(new Date(range[0]));
  const [to, setTo] = useState(new Date(range[1]));

  const [updateTime, setUpdateTime] = useState(now());

  const pageCount = transactions
    ? Math.ceil(transactions.length / PER_PAGE)
    : 0;
  const pageTransactions = pagize(transactions, page, PER_PAGE);

  useEffect(() => {
    if (qs.page !== page) {
      setPage(qs.page || 0);
    }

    if (qs.rangeType !== rangeType) {
      const type = qs.rangeType || DEFAULT_RANGE_TYPE;

      setSearchRangeType(type);
      setRangeType(type);
      setRange([
        rangeStart({ rangeType: type, from: qs.from }),
        rangeEnd({ rangeType: type, to: qs.to }),
      ]);
    }

    if (qs.queryType !== queryType) {
      const type = qs.queryType || '';

      setSearchQueryType(type);
      setQueryType(type);
    }

    if (qs.query !== query) {
      const q = qs.query || '';

      setSearchQuery(q);
      setQuery(q);
    }
  }, [qs]);

  useEffect(() => {
    const params = {
      created_after_or_at: range[0],
      created_before_or_at: rangeEnd({ rangeType, to: range[1] }),
      limit: BUCKET_SIZE,
    };

    if (queryType && query && query !== 'all') {
      params[queryType] = query;
    }

    setTransactions();

    axios
      .get('/transactions', {
        params,
      })
      .then(res => {
        res.data.sort((a, b) => b.created_at - a.created_at);
        setTransactions(res.data);
      })
      .catch(setError);
  }, [queryType, query, range, updateTime]);

  const gotoPage = p => {
    setPage(p);
    setQS(
      {
        page: p,
      },
      'pushIn'
    );
    scrollMain(0, 0);
  };

  const onSearch = event => {
    event.preventDefault();

    const data = new FormData(event.target);

    const inputQueryType = data.get('queryType');
    const inputQuery = data.get('query');

    const inputRangeType = data.get('rangeType');
    // $FlowFixMe: FormDataEntryValue type issue
    const inputRangeFrom = new Date(data.get('from')).getTime();
    // $FlowFixMe: FormDataEntryValue type issue
    const inputRangeTo = new Date(data.get('to')).getTime();

    // $FlowFixMe: FormDataEntryValue type issue
    setQueryType(inputQueryType);
    setQuery(inputQuery);

    // $FlowFixMe: FormDataEntryValue type issue
    setRangeType(inputRangeType);
    setRange([
      // $FlowFixMe: FormDataEntryValue type issue
      rangeStart({ rangeType: inputRangeType, from: inputRangeFrom }),
      // $FlowFixMe: FormDataEntryValue type issue
      rangeEnd({ rangeType: inputRangeType, to: inputRangeTo }),
    ]);

    const newQS: Object = {
      queryType: inputQueryType,
      query: inputQuery,
      rangeType: inputRangeType,
    };

    if (inputRangeType === 'range') {
      newQS.from = inputRangeFrom;
      newQS.to = inputRangeTo;
    }

    setQS(newQS, 'push');
  };

  const queryTypes = [
    {
      option: 'id',
      label: 'Transaction ID',
      nodatetime: true,
    },
    {
      option: 'type',
      label: 'Transaction Type',
      values: txTypes,
    },
    {
      option: 'payment_id',
      label: 'Payment ID',
      nodatetime: true,
    },
    {
      option: 'payment_division_id',
      label: 'Business Division',
      values: divisions,
    },
    {
      option: 'payment_status',
      label: 'Status',
      values: paymentStatus,
    },
  ];

  dispatch(
    updateUIPairs({
      transactionQString: qs ? stringify(encodeQueryParams(qsDef, qs)) : '',
    })
  );

  return (
    <>
      <Header>
        <h2>Transactions</h2>
        <SearchForm
          queryTypes={queryTypes}
          onSearch={onSearch}
          searchRangeType={searchRangeType}
          setSearchRangeType={setSearchRangeType}
          from={from}
          setFrom={setFrom}
          to={to}
          setTo={setTo}
          searchQueryType={searchQueryType}
          setSearchQueryType={setSearchQueryType}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
      </Header>
      <Main>
        {!transactions && !error && <Loading />}
        {!transactions && error && <ErrorResult />}
        {transactions && transactions.length === 0 && <NoResult />}
        {transactions && transactions.length > 0 && (
          <>
            <Pagination
              isTop
              page={page}
              pageCount={pageCount}
              gotoPage={gotoPage}
              updateTime={updateTime}
              setUpdateTime={setUpdateTime}
            />
            <Table id="transactions">
              <thead>
                <tr>
                  <th>Created at</th>
                  <th>Transaction ID</th>
                  <th>Payment ID</th>
                  <th className="align-right">Amount</th>
                  <th>Division</th>
                </tr>
              </thead>
              <tbody>
                {pageTransactions.map((t, index) => (
                  <tr key={`${index}-${t.id}`}>
                    <td>
                      <time>{dateString(new Date(t.created_at))}</time>
                    </td>
                    <td>
                      <div className={styles['transaction-id']}>{t.id}</div>
                      <span className={`tx-span ${t.type}`}>{t.type}</span>
                    </td>
                    <td>
                      <Link
                        to={`/payments/${t.payment.id}`}
                        className={cx('bold', styles['payment-link'])}
                      >
                        {t.payment.id}
                      </Link>
                      <span className={`payment-span ${t.payment.status}`}>
                        {t.payment.status}
                      </span>
                      {!!t.rejection_detail_code && (
                        <RejectionReason reasonCode={t.rejection_detail_code} />
                      )}
                    </td>
                    <td className={cx('bold', 'align-right')}>
                      {currencyFormater.format(t.amount)}
                    </td>
                    <td className="noto-sans">
                      {divisionMap[t.payment.division_id] ||
                        t.payment.division_id}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination
              page={page}
              pageCount={pageCount}
              gotoPage={gotoPage}
              updateTime={updateTime}
              setUpdateTime={setUpdateTime}
            />
          </>
        )}
      </Main>
    </>
  );
};

export default TransactionsPage;
