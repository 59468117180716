// @flow
import React from 'react';
import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
// import { createLogger } from 'redux-logger';
import axios from 'axios';

import './i18n';
import configureStore from './createStore';
import App from './AppContainer';
import * as serviceWorker from './serviceWorker';

import './index.scss';

// const loggerMiddleware = createLogger();

const voidFn = () => {};

if (process.env.NODE_ENV !== 'development') {
  // $FlowFixMe
  console.log = voidFn;
  // $FlowFixMe
  console.warn = voidFn;
  // $FlowFixMe
  console.error = voidFn;
}

const store = configureStore({});
const persistor = persistStore(store);

axios.defaults.baseURL =
  'https://f421f28a-164a-4f6a-bfc7-65f5f3cf41ec.mock.pstmn.io';
axios.defaults.headers.post['Content-Type'] = 'application/json';

const rootEl = document.getElementById('root');

if (rootEl) {
  if (rootEl.hasChildNodes()) {
    hydrate(
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>,
      rootEl
    );
  } else {
    render(
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>,
      rootEl
    );
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
