// @flow
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createSession } from '../../redux/auth';

import styles from './LoginPage.module.scss';
import button from '../Base/Button.module.scss';

const LoginPage = () => {
  const dispatch = useDispatch();
  const authFailed = useSelector(state => state.auth.failed);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const onLogin = event => {
    event.preventDefault();

    dispatch(createSession({ username, password }));
  };

  return (
    <>
      <div className={styles['login-root']}>
        <form className={styles['login-form']} onSubmit={onLogin}>
          <h1>
            <img
              src="/assets/logo-color.svg"
              width="118"
              height="62"
              alt="Paidy"
            />
          </h1>

          <p>Merchant Login</p>

          <div className={styles['login-error-msg']}>
            {authFailed && 'Incorrect email address or password entered.'}
          </div>

          <fieldset>
            <input
              id="username"
              name="username"
              type="text"
              value={username}
              placeholder="Username"
              onChange={event => setUsername(event.target.value)}
            />
          </fieldset>
          <fieldset>
            <input
              id="password"
              name="password"
              type="password"
              value={password}
              placeholder="Password"
              onChange={event => setPassword(event.target.value)}
            />
          </fieldset>

          <fieldset>
            <input type="submit" value="Log In" className={button.primary} />
          </fieldset>
        </form>
        <div className={styles.copy}>© Paidy Inc</div>
      </div>
    </>
  );
};

export default LoginPage;
