import React from 'react';

import MainNav from './Nav';
import Session from './Session';

import styles from './Header.module.scss';

const Header = ({ userToken }: { userToken: string }) => {
  const loggedin = (
    <>
      <MainNav />
      <Session />
    </>
  );

  return (
    <div id={styles.header}>
      <h1>
        <img
          src="/assets/logo-white.svg"
          width="55"
          height="29"
          alt="Paidy Merchant Dashboard"
        />
      </h1>
      {userToken && loggedin}
    </div>
  );
};

export default Header;
