// @flow
import React from 'react';
import cx from 'classnames';
import Paginate from 'react-paginate';

import { now, dateString } from '../../utils';

import styles from './Pagination.module.scss';
import button from '../Base/Button.module.scss';

type Props = {
  page: number,
  gotoPage: Function,
  updateTime: number,
  setUpdateTime: Function,
  pageCount: number,
  isTop?: boolean,
};

const Pagination = ({
  page,
  gotoPage,
  updateTime,
  setUpdateTime,
  pageCount,
  isTop,
}: Props) => {
  return (
    <div className={cx(styles.paginationContainer, isTop ? styles.top : '')}>
      <Paginate
        forcePage={page}
        previousLabel={'Prev'}
        nextLabel={'Next'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={4}
        onPageChange={({ selected }) => {
          gotoPage(selected);
        }}
        containerClassName={styles.pagination}
        subContainerClassName={cx(styles.pagination, styles.page)}
        activeClassName={'active'}
      />
      <div className={styles.right}>
        <span
          className={styles['last-updated']}
        >{`Last updated at: ${dateString(new Date(updateTime))}`}</span>
        <button
          onClick={() => setUpdateTime(now())}
          className={button.secondary}
        >
          Update
        </button>
      </div>
    </div>
  );
};

Pagination.defaultProps = {
  isTop: false,
};

export default Pagination;
