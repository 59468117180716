// @flow
import React from 'react';
import { navigate } from '@reach/router';

import styles from './NotFound.module.scss';
import button from '../Base/Button.module.scss';

const NotFound = ({ message }: { message?: string }) => {
  return (
    <div className={styles['block-root']}>
      <div className={styles['block-form']}>
        <h1>
          <img
            src="/assets/logo-color.svg"
            width="118"
            height="62"
            alt="Paidy"
          />
        </h1>
        <p>{message}</p>
        <fieldset>
          <button
            className={button.secondary}
            onClick={() => {
              navigate('/');
            }}
          >
            Back to home
          </button>
        </fieldset>
      </div>
      <div className={styles.copy}>© Paidy Inc</div>
    </div>
  );
};

NotFound.defaultProps = {
  message: 'Page not found',
};

export default NotFound;
