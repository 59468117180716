// @flow
import React from 'react';
import { useSelector } from 'react-redux';

import NavLink from './NavLink';

import { urlBuilder } from '../../utils';

import styles from './Nav.module.scss';

const MainNav = () => {
  const transactionQString =
    useSelector((state) => state.ui.transactionQString) || '';

  return (
    <nav className={styles['main-nav']}>
      <NavLink
        to={urlBuilder({ pathname: '/transactions', qs: transactionQString })}
        title="Transactions"
        className=""
        activeClassName={styles.active}
        isActive={({ isPartiallyCurrent, location }) => {
          return (
            isPartiallyCurrent ||
            /^\/(payments|transactions)/.test(location.pathname)
          );
        }}
      >
        Transactions
      </NavLink>
      <NavLink
        to="/reports"
        title="Report"
        className=""
        activeClassName={styles.active}
      >
        Reports
      </NavLink>
    </nav>
  );
};

export default MainNav;
