// @flow
import React from 'react';
import idx from 'idx';
import DateTimePicker from 'react-datetime-picker';

import styles from './SearchForm.module.scss';
import button from '../Base/Button.module.scss';

const SearchForm = ({
  queryTypes,
  onSearch,
  searchRangeType,
  setSearchRangeType,
  from,
  setFrom,
  to,
  setTo,
  searchQueryType,
  setSearchQueryType,
  searchQuery,
  setSearchQuery,
}: {
  queryTypes: Object,
  onSearch: Function,
  searchRangeType: string,
  setSearchRangeType: Function,
  from: Object,
  setFrom: Function,
  to: Object,
  setTo: Function,
  searchQueryType: string,
  setSearchQueryType: Function,
  searchQuery: string,
  setSearchQuery: Function,
}) => {
  const queryTypeMapping = queryTypes.reduce((mapping, entry) => {
    mapping[entry.option] = entry;

    return mapping;
  }, {});

  const queryTypeOptions = queryTypes.map(entry => {
    return {
      label: entry.label || entry.option,
      value: entry.option,
    };
  });

  const nodatetime = idx(queryTypeMapping, _ => _[searchQueryType].nodatetime);

  return (
    <form onSubmit={onSearch} className={styles['search-form']}>
      <div className={styles.top}>
        <div className="radio-group">
          <span className={styles['top-label']}>Date:</span>
          <label>
            <input
              type="radio"
              name="rangeType"
              value="today"
              checked={searchRangeType === 'today'}
              disabled={nodatetime}
              onChange={() => {
                setSearchRangeType('today');
              }}
            />
            <span className="radio-label-text">Today</span>
          </label>
          <label>
            <input
              type="radio"
              name="rangeType"
              value="thisweek"
              checked={searchRangeType === 'thisweek'}
              disabled={nodatetime}
              onChange={() => {
                setSearchRangeType('thisweek');
              }}
            />
            <span className="radio-label-text">This Week</span>
          </label>
          <label>
            <input
              type="radio"
              name="rangeType"
              value="thismonth"
              checked={searchRangeType === 'thismonth'}
              disabled={nodatetime}
              onChange={() => {
                setSearchRangeType('thismonth');
              }}
            />
            <span className="radio-label-text">This Month</span>
          </label>
          <label>
            <input
              type="radio"
              name="rangeType"
              value="range"
              checked={searchRangeType === 'range'}
              disabled={nodatetime}
              onChange={() => {
                setSearchRangeType('range');
              }}
            />
            <span className="radio-label-text">Range</span>
          </label>
        </div>

        <DateTimePicker
          disableClock
          name="from"
          onChange={setFrom}
          value={from}
          id="from_date_picker"
          clearIcon={null}
          disabled={searchRangeType !== 'range' || nodatetime}
        />
        <span className="datetime-picker-separator">~</span>
        <DateTimePicker
          disableClock
          name="to"
          onChange={setTo}
          value={to}
          id="to_date_picker"
          clearIcon={null}
          disabled={searchRangeType !== 'range' || nodatetime}
        />
      </div>
      <div className={styles.bottom}>
        <div className="select">
          <select
            name="queryType"
            value={searchQueryType}
            id="type_dropdown"
            onChange={event => {
              setSearchQueryType(event.currentTarget.value);
              setSearchQuery('');
            }}
          >
            <option value="-1" disabled>
              Search By
            </option>
            <option value="">Any</option>
            {queryTypeOptions.map(option => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        {queryTypeMapping[searchQueryType] &&
          !queryTypeMapping[searchQueryType].values && (
            <input
              type="text"
              name="query"
              placeholder="search terms"
              id="search_query"
              value={searchQuery}
              onChange={event => {
                setSearchQuery(event.currentTarget.value);
              }}
            />
          )}
        {queryTypeMapping[searchQueryType] &&
          queryTypeMapping[searchQueryType].values && (
            <div className="select">
              <select
                name="query"
                value={searchQuery}
                id="search_dropdown"
                onChange={event => setSearchQuery(event.currentTarget.value)}
              >
                <option value="" disabled>
                  Select
                </option>
                {queryTypeMapping[searchQueryType].values.map(v => (
                  <option key={v.option} value={v.option}>
                    {v.label || v.option}
                  </option>
                ))}
              </select>
            </div>
          )}
        <input type="submit" value="Search" className={button.primary} id="search_submit" />
      </div>
    </form>
  );
};

export default SearchForm;
