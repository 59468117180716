// @flow
import React from 'react';

import Header from '../Main/Header';
import Main from '../Main/Main';

import styles from './ReportPage.module.scss';
import button from '../Base/Button.module.scss';

const REMITTANCE_REPORT_URL =
  'https://s3.console.aws.amazon.com/s3/buckets/production-01-merchant-reports/published/reports/amazon-remittance/';

const DEPOSIT_REPORT_URL =
  'https://s3.console.aws.amazon.com/s3/buckets/production-01-merchant-reports/published/reports/amazon-deposit/';

const ReportPage = () => {
  return (
    <>
      <Header>
        <h2>Reports</h2>
      </Header>
      <Main className={styles.main}>
        <div className={styles.reports}>
          <div className={styles.reportBlock}>
            <h3>Remittance Report</h3>
            <p>Daily remittance reports are available in Paidy’s S3 bucket.</p>
            <div>
              <a
                href={REMITTANCE_REPORT_URL}
                target="_blank"
                rel="noopener noreferrer"
                className={button.secondary}
              >
                View Remittance Report
              </a>
            </div>
          </div>
          <div className={styles.reportBlock}>
            <h3>Deposit Report</h3>
            <p>Monthly deposit reports are available in Paidy’s S3 bucket.</p>
            <div>
              <a
                href={DEPOSIT_REPORT_URL}
                target="_blank"
                rel="noopener noreferrer"
                className={button.secondary}
              >
                View Deposit Report
              </a>
            </div>
          </div>
        </div>
      </Main>
    </>
  );
};

export default ReportPage;
