// @flow
const ONE_DAY = 24 * 60 * 60 * 1000;

export const now = (): number => new Date().getTime();

export const todayStart = (): number => {
  const today = new Date();

  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);

  return today.getTime();
};

export const thisweekStart = (): number => {
  const today = new Date();

  const deltaDay = today.getDay();

  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);

  return today.getTime() - deltaDay * ONE_DAY;
};

export const thismonthStart = (): number => {
  const today = new Date();

  today.setDate(1);
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);

  return today.getTime();
};

export const rangeStart = ({
  rangeType,
  from,
}: {
  rangeType: string,
  from: number,
}): number => {
  switch (rangeType) {
    case 'today':
      return todayStart();
    case 'thisweek':
      return thisweekStart();
    case 'thismonth':
      return thismonthStart();
    default:
      return from || todayStart();
  }
};

export const rangeEnd = ({
  rangeType,
  to,
}: {
  rangeType: string,
  to: number,
}): number => {
  switch (rangeType) {
    case 'today':
    case 'thisweek':
    case 'thismonth':
      return now();
    default:
      return to || now();
  }
};

export const dateString = (d: any): string => {
  return d.toLocaleDateString('ja-JP', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
};

export const pagize = (
  entries: ?Array<any>,
  page: number,
  perPage: number
): Array<any> => {
  if (!entries) {
    return [];
  }

  return entries.slice(page * perPage, page * perPage + perPage);
};

export const currencyFormater = (() => {
  if (Intl !== undefined && Intl.NumberFormat) {
    return new Intl.NumberFormat('ja-JP', {
      style: 'currency',
      currency: 'JPY',
    });
  }

  return {
    format(input: number) {
      return `¥${input}`;
    },
  };
})();

export const urlBuilder = ({
  pathname,
  qs,
}: {
  pathname: string,
  qs: string,
}): string => {
  if (qs) {
    return `${pathname}?${qs}`;
  }

  return pathname;
};
