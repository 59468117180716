// @@flow
import { navigate } from '@reach/router';

import axios from '../axios';

const UPDATE_PAIRS = '@@amazon_md/auth/update_pairs';
const CREATE_SESSION = 'CREATE_SESSION';
// const CREATE_SESSION_FAILED = 'CREATE_SESSION_FAILED';
// const RECEIVE_SESSION = 'RECEIVE_SESSION';

const initialState = {
  id: '',
  username: '',
  failed: false,
};

const authPairs = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PAIRS: {
      return {
        ...state,
        id: action.pairs.id,
        userToken: action.pairs.id,
        username: action.pairs.username,
        failed: action.pairs.failed,
      };
    }
    default:
      return state;
  }
};

export const updateAuthPairs = data => ({
  type: UPDATE_PAIRS,
  pairs: data,
});

const createEvent = () => {
  return {
    type: CREATE_SESSION,
  };
};

const createFailedEvent = () => {
  return updateAuthPairs({ failed: true });
};

const receiveEvent = data => {
  return updateAuthPairs(data);
};

const _createSession = ({ username, password }) => {
  return dispatch => {
    dispatch(createEvent());

    return axios
      .post(`/sessions`, {
        email: username,
        password,
      })
      .then(resp => dispatch(receiveEvent(resp.data)))
      .catch(() => {
        dispatch(createFailedEvent());
      });
  };
};

const _deleteSession = () => {
  return updateAuthPairs({
    id: '',
    userToken: '',
    username: '',
  });
};

export const createSession = (params: any = {}) => {
  return (dispatch: Function) => {
    return dispatch(_createSession(params));
  };
};

export const deleteSession = () => {
  return (dispatch: Function) => {
    dispatch(_deleteSession());
    navigate('/');
  };
};

export default authPairs;
