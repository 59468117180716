// @flow
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { deleteSession } from '../../redux/auth';

import styles from './Session.module.scss';

const Session = () => {
  const dispatch = useDispatch();
  const username = useSelector(state => state.auth.username);

  const logout = () => {
    dispatch(deleteSession());
  };

  return (
    <div className={styles['session-panel']}>
      <b>{username}</b>
      <button onClick={logout}>Logout</button>
    </div>
  );
};

export default Session;
