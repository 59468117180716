// @flow
import React from 'react';

import styles from './Header.module.scss';

const SubHeader = (props: any) => {
  return (
    <div className={styles.subheader}>
      <div className="container">{props.children}</div>
    </div>
  );
};

export default SubHeader;
