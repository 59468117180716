// @flow
import React, { Suspense, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Router } from '@reach/router';

import Header from './components/Header/Header';
import NotFound from './components/NotFound/NotFound';
import LoginPage from './components/LoginPage/LoginPage';
import Loading from './components/Loading/Loading';
import HomePage from './components/HomePage/HomePage';
import ReportPage from './components/ReportPage/ReportPage';
import TransactionsPage from './components/TransactionsPage/TransactionsPage';
import PaymentPage from './components/PaymentPage/PaymentPage';

import styles from './App.module.scss';

const App = () => {
  const userToken = useSelector((state) => state.auth.userToken);
  const mainEl = useRef(null);

  const scrollMain = (x, y) => {
    if (mainEl && mainEl.current) {
      // $FlowFixMe: Cannot call `mainEl.current.scrollTo` because property `scrollTo` is missing in  `HTMLElement` [1].Flow(InferError)
      mainEl.current.scrollTo(x, y);
    }
  };

  const main = !userToken ? (
    <LoginPage />
  ) : (
    <div className={styles.root}>
      <main className={styles.main} ref={mainEl}>
        <Router>
          <NotFound default />
          <HomePage path="/" />
          <TransactionsPage path="/transactions" scrollMain={scrollMain} />
          {/** $FlowFixMe */}
          <PaymentPage path="/payments/:paymentId" />
          <ReportPage path="/reports" />
        </Router>
      </main>
    </div>
  );

  return (
    <>
      <Header userToken={userToken} />
      {main}
    </>
  );
};

export default (props: Object) => (
  <Suspense fallback={<Loading />}>
    <App {...props} />
  </Suspense>
);
