// @flow
import React from 'react';
import cx from 'classnames';

import styles from './Main.module.scss';

const Main = (props: any) => {
  return (
    <div className={cx(styles.main, props.className)}>
      <div className="container">{props.children}</div>
    </div>
  );
};

export default Main;
