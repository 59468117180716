// @flow
/* eslint-disable import/prefer-default-export */

export const API_HOST_PRODUCTION = 'https://apis.paidy.com/amazon/dashboard';
export const API_HOST_STAGING =
  'https://apis.paidy-staging.com/amazon/dashboard';

export const API_HOST =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? API_HOST_PRODUCTION
    : API_HOST_STAGING;

export const langMap = {
  en: 'English',
  'en-US': 'English',
  ja: '日本語',
};
export const languages = ['en', 'ja'];
export const divisions = [
  {
    option: 'JPRET001',
    label: 'Retail',
  },
  {
    option: 'JPMP002',
    label: 'Marketplace',
  },
  {
    option: 'JPPRM003',
    label: 'Prime Subs',
  },
  {
    option: 'JPKDL004',
    label: 'Kindle',
  },
  {
    option: 'JPMUL005',
    label: 'Digital Music',
  },
  {
    option: 'JPPHA006',
    label: 'Pharmacy',
  },
  {
    option: 'JPAPY007',
    label: 'Amazon Pay',
  },
  {
    option: 'JPVID008',
    label: 'Prime Video',
  },
];
export const divisionMap = {
  JPRET001: 'Retail',
  JPMP002: 'Marketplace',
  JPPRM003: 'Prime Subs',
  JPKDL004: 'Kindle',
  JPMUL005: 'Digital Music',
  JPPHA006: 'Pharmacy',
  JPAPY007: 'Amazon Pay',
  JPVID008: 'Prime Video',
};
export const paymentStatus = [
  { option: 'authorized', label: 'Authorized' },
  { option: 'rejected', label: 'Rejected' },
  { option: 'closed', label: 'Closed' },
  { option: 'all', label: 'All' },
];
export const txTypes = [
  { option: 'create', label: 'Create' },
  { option: 'capture', label: 'Capture' },
  { option: 'cancel', label: 'Cancel' },
  { option: 'refund', label: 'Refund' },
  { option: 'all', label: 'All' },
];
export const regStatus = [
  { option: 'active', label: 'Active' },
  { option: 'suspended', label: 'Suspended' },
  { option: 'rejected', label: 'Rejected' },
  { option: 'deleted', label: 'Deleted' },
  { option: 'all', label: 'All' },
];

export const PER_PAGE = 100;
export const BUCKET_SIZE = 1000;
export const DEFAULT_RANGE_TYPE = 'today';
