// @flow
export default {
  'request.content_malformed':
    'Retry will not help to resolve the transaction failure.',
  'authentication.failed':
    'Will not retry as this will require code changes on our side if we pass wrong bearer key/authentication detail to Paidy.',
  'authorization.failed':
    'Will not retry as this will require code changes on our side if we pass wrong bearer key/authentication detail to Paidy.',
  'account.suspended': 'Retry will not help to resolve the transaction failure',
  'account.closed': 'Retry will not help to resolve the transaction failure',
  'consumer.email.mismatch':
    'Retry will not help to resolve the transaction failure',
  'consumer.phone.mismatch':
    'Retry will not help to resolve the transaction failure',
  'consumer.billing_address.invalid':
    'Retry will not help to resolve the transaction failure',
  'request.idempotency_validation.error':
    'Idempotency error: if subsequent request has different payload than original request.',
};
