// @flow
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from '@reach/router';

import cx from 'classnames';

import NotFound from '../NotFound/NotFound';
import Header from '../Main/Header';
import Main from '../Main/Main';
import Table from '../Table/Table';

import RejectionReason from '../TransactionsPage/RejectionReason';

import useAxios from '../../hooks/useAxios';

import { dateString, currencyFormater, urlBuilder } from '../../utils';

import { divisionMap } from '../../constants';

import styles from './PaymentPage.module.scss';
import button from '../Base/Button.module.scss';

const Transactions = ({ transactions }) => {
  if (!transactions || !transactions.length) {
    return null;
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>Created at</th>
          <th>Transaction ID</th>
          <th>Type</th>
          <th className="align-right">Amount</th>
        </tr>
      </thead>
      <tbody>
        {transactions.map(t => (
          <tr key={t.id}>
            <td>{dateString(new Date(t.created_at))}</td>
            <td className="bold">{t.id}</td>
            <td>
              <span className={`tx-span ${t.type}`}>{t.type}</span>
            </td>
            <td className={cx('bold', 'align-right')}>
              {currencyFormater.format(t.amount)}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const PaymentPage = ({ paymentId }: { paymentId: string }) => {
  const transactionQString =
    useSelector(state => state.ui.transactionQString) || '';

  const axios = useAxios();
  const [payment, setPayment] = useState();

  useEffect(() => {
    axios
      .get(`/payments/${paymentId}`)
      .then(res => {
        setPayment(res.data);
      })
      .catch(error => {
        setPayment(null);
        console.log(error);
      });
  }, []);

  // undefined is not fetch complete
  if (payment === undefined) {
    return null;
  }

  // null is fetch complete but not got payment
  if (payment === null) {
    return <NotFound message="Payment not found" />;
  }

  return (
    <>
      <Header>
        <h2>Payment details</h2>
        <div className={styles.payment_info}>
          <div className={styles.meta}>
            <div>
              <span className={styles.label}>Selected Payment ID</span>
              <span className={styles.value}>{paymentId}</span>
            </div>
            <div>
              <span className={cx(styles.label, 'align-right')}>Amount</span>
              <span className={cx(styles.value, 'align-right')}>
                {currencyFormater.format(payment.amount)}
              </span>
            </div>
            <div>
              <span className={styles.label}>Division</span>
              <span className={styles.value}>
                {divisionMap[payment.division_id] || payment.division_id}
              </span>
            </div>
          </div>
          <div>
            <span className={`payment-span ${payment.status}`}>
              {payment.status}
            </span>
            {payment.rejection_detail_code && (
              <RejectionReason
                reasonCode={payment.rejection_detail_code}
                className={styles['rejection-code']}
              />
            )}
          </div>
        </div>
      </Header>
      <Main>
        <Transactions transactions={payment.transactions} />
        <div className={styles.extra}>
          <Link
            to={urlBuilder({
              pathname: '/transactions',
              qs: transactionQString,
            })}
            className={button.secondary}
          >
            Go back to search results
          </Link>
        </div>
      </Main>
    </>
  );
};

export default PaymentPage;
