// @flow
import React from 'react';
import { Link } from '@reach/router';
import cx from 'classnames';

const NavLink = ({
  activeClassName,
  className,
  to,
  title,
  isActive,
  children,
}: {
  activeClassName: string,
  className?: string,
  to: string,
  title: string,
  isActive?: Function,
  children: React$Node,
}) => (
  <Link
    title={title}
    to={to}
    className={className}
    getProps={({ isPartiallyCurrent, location }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: (isActive
        ? isActive({ isPartiallyCurrent, location })
        : isPartiallyCurrent)
          ? cx(className, activeClassName)
          : className,
      };
    }}
  >
    {children}
  </Link>
);

NavLink.defaultProps = {
  activeClassName: '',
  className: '',
  isActive: null,
};

export default NavLink;
