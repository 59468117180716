// @flow
import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: ['ja', 'en'],
    // lng: 'en', // if we wanna override browser lang
    // have a common namespace used around the full app
    ns: ['amazon_md'],
    defaultNS: 'amazon_md',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      wait: true,
    },
  });

export default i18n;
