// @flow
import React, { useEffect } from 'react';
import { navigate } from '@reach/router';

const HomePage = () => {
  useEffect(() => {
    navigate('/transactions');
  });

  return <></>;
};

export default HomePage;
